.Page_Home{
    width: 100%;
    height: 540px;
    position: relative;
    padding-top: 100px;

    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
}
.Page_Home .show_btn{
    position: absolute;
    z-index: 1;
    width: calc(100% - 80px);
    height: calc(100% - 100px);
    display: flex;
    flex-direction: row;
}
.Page_Home .show_btn .div_arrow{
    width: 80px;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
}
.Page_Home .show_btn .div_arrow:nth-child(1){
    left: 0;
    justify-content: flex-end;
}
.Page_Home .show_btn .div_arrow:nth-child(2){
    right: 0;
    justify-content: flex-start;
}
.Page_Home .show_btn .div_arrow .icons{
    width: 40px;
    height: 40px;
}
.Page_Home .show_btn .div_arrow .icons_left {
    transform: rotate(180deg);
}
.Page_Home .div_list_img{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
}
.Page_Home .div_list_img .show_img{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-width: 100%;
    height: 100%;
    position: absolute;
    transition: 1s;
}
.Page_Home .div_list_img .show_img .container{
    width: min-content;
    height: 100%;
    position: relative;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}   
.Page_Home .div_list_img .show_img:nth-child(1){
    left: -100%;
}
.Page_Home .div_list_img .show_img:nth-child(2){
    left: 0;
}
.Page_Home .div_list_img .show_img:nth-child(n+3){
    left: 100%;
}
.Page_Home .div_list_img .show_img .title{
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Poppins';
    font-size: 52px;
    line-height: 46px;
    font-weight: 600;

    position: relative;
    color: transparent; 
    -webkit-text-stroke: 0px 0px rgb(255 181 0);
    text-transform: uppercase;
    white-space: nowrap;
    transition: 0.5s;
}
.Page_Home .div_list_img .show_img .title_close{
    opacity: 0;
}
.Page_Home .div_list_img .show_img .subtitle{
    font-size: 32px;
    font-weight: 400; 
    -webkit-text-stroke: 0px 0px #ffffff;
}
.Page_Home .div_list_img .show_img .title::before {
    content: attr(data-text);
    position: absolute;
    left: 0px;
    width: 0%;
    height: auto;
    -webkit-text-stroke: 0vw 0px rgb(255 181 0);
    display: flex;
    align-items: center;

    animation-duration: 3s;
    color: rgb(255 181 0); 
    animation-name: title_slide_before;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    overflow: hidden;    

    text-shadow: #000 1px 1px 2px;
}
.Page_Home .div_list_img .show_img .subtitle::before {
    color: #ffffff;
}


/* video */
.Page_Home .div_list_img .show_img .video_background {
    background: #000;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -99;
}
.Page_Home .div_list_img .show_img .video_background video{
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
}
.Page_Home .div_list_img .show_img .video_foreground, .Page_Home .div_list_img .show_img .video_background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    scale: 1;
}
@media (min-aspect-ratio: 16/9) {
    .Page_Home .div_list_img .show_img .video_foreground { 
        height: 300%; 
        top: -100%; 
    }
}
@media (max-aspect-ratio: 16/9) {
    .Page_Home .div_list_img .show_img .video_foreground { 
        width: 300%; 
        left: -100%; 
    }
}
@media all and (max-width: 1200px) {
    .Page_Home .div_list_img .show_img .video_foreground, .Page_Home .div_list_img .show_img .video_background iframe {
        top: -50px;
        height: 120%;
    }
}
@media all and (max-width: 1010px) {
    .Page_Home .div_list_img .show_img .video_background video{
        width: 120%;
    }
}
@media all and (max-width: 950px) {
    .Page_Home .div_list_img .show_img .video_foreground, .Page_Home .div_list_img .show_img .video_background iframe {
        top: -20px;
        height: 110%;
    }
}
@media all and (max-width: 830px) {
    .Page_Home .div_list_img .show_img .video_background video{
        width: 180%;
    }
}
@media all and (max-width: 600px) {
    .Page_Home .div_list_img .show_img .vid-info { 
        width: 50%; 
        padding: .5rem; 
    }
    .Page_Home .div_list_img .show_img .vid-info h1 { 
        margin-bottom: .2rem; 
    }
}
@media all and (max-width: 580px) {
    .Page_Home .div_list_img .show_img .video_background video{
        width: 240%;
    }
}
@media all and (max-width: 500px) {
    .Page_Home .div_list_img .show_img .vid-info .acronym {
        display: none;
    }
}
@media all and (max-width: 460px) {
    .Page_Home .div_list_img .show_img .video_foreground, .Page_Home .div_list_img .show_img .video_background iframe {
        scale: 1.5;
    }
    .Page_Home .div_list_img .show_img .video_foreground, .Page_Home .div_list_img .show_img .video_background iframe{
        top: 72px;
        height: 80%;
    }
}
@media all and (max-width: 430px) {
    .Page_Home .div_list_img .show_img .video_background video{
        width: 280%;
    }
}
/* end */


@keyframes title_slide_before {
    0%, 20%{
        width: 0%;
        border-right: 2px solid rgb(0 39 104);
    }
    99%{
        border-right: 2px solid rgb(0 39 104);
    }
    100%{
        width: 100%;
    }
}
@media only screen and (max-width: 950px) {
    .Page_Home{
        padding-top: 70px;
    }
    .Page_Home .show_btn{
        height: calc(100% - 70px);
    }
}
@media only screen and (max-width: 580px) {
    .Page_Home .div_list_img .show_img .title{
        font-size: 32px;
        line-height: 30px;
    }
    .Page_Home .div_list_img .show_img .subtitle{
        font-size: 18px;
        line-height: 18px;
    }
    .Page_Home .show_btn .div_arrow{
        width: auto;
    }
}