.PopUp .request_budget{
    width: 800px;
    font-family: 'inter';
}
.PopUp .request_budget .list_data_input{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
    position: relative;
    padding-top: 10px;
}
.PopUp .request_budget .list_data_input:nth-child(n+2) {
    padding-top: 20px;
}
.PopUp .request_budget .list_data_input .div_input{
    width: auto;
    position: relative;
}
.PopUp .request_budget input{
    font-size: 14px;
    padding: 10px;
    display: block;
    width: calc(100% - 20px);
    border: 1px solid #324d6b;
    border-radius: 4px;
    background: transparent;
    color: #000000;
    font-family: 'inter';
    outline: none;
}
.PopUp .request_budget .list_data_input .div_input .textarea{
    width: calc(100% - 20px);
    resize: none;
    font-size: 14px;
    padding: 10px;
    color: #000000;
    border: 1px solid #324d6b;
    min-height: 40px;
    outline: none;
    border-radius: 4px;
}
.PopUp .request_budget .list_data_input .div_input .name_input{
    position: absolute;
    top: -12px;
    left: 10px;
    background-color: #ffffff;
    padding: 0px 10px;
}
.PopUp .request_budget .list_data_input .btn{
    width: 100%;
    color: #ffffff;
    border: transparent;
    background-color: #002d6a;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    text-transform: uppercase;
}

.PopUp .request_budget .direction_column{
    flex-direction: column;
    gap: 10px;
}
.PopUp .request_budget .direction_column .list_title{
    font-size: 16px;
    font-weight: 600;
}
.PopUp .request_budget .direction_column .show_panel{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.PopUp .request_budget .direction_column .show_panel .show_data_panel{
    width: calc(100% - 22px);
    padding: 10px;
    text-align: left;
    border: 1px solid #324d6b;
    border-radius: 4px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.PopUp .request_budget .direction_column .show_panel .show_data_panel .list_name_panel{
    text-align: center;
}
.PopUp .request_budget .direction_column .show_panel .show_data_panel .list_data{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
}
.PopUp .request_budget .direction_column .show_panel .show_data_panel .list_data input{
    width: auto;
}


@media only screen and (max-width: 1200px) {
    .PopUp .request_budget{
        width: 90%;
    }
    .PopUp .request_budget .list_data_input{
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: 670px) {
    .PopUp .request_budget .direction_column .show_panel .show_data_panel .list_data{
        flex-direction: column;
    }
}