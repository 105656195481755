.Page_Broadcast{
    width: 100%;
    height: auto;
    padding-top: 100px;
    padding-bottom: 80px;
    background-color: #f7f7f7;
}
.Page_Broadcast .div_broadcast{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    width: 800px;
    padding-top: 60px;
}
.Page_Broadcast .div_broadcast .title{
    font-family: 'Bebas Neue';
    width: 100%;
    text-align: center;
    font-size: 32px;
    line-height: 32px;
}
.Page_Broadcast .div_broadcast .list_data_week{
    width: 100%;
}
.Page_Broadcast .div_broadcast .list_data_week .opt_file{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 4px;
    font-family: 'inter';
}
.Page_Broadcast .div_broadcast .list_data_week .opt_file .btn{
    padding: 10px;
    cursor: pointer;
}
.Page_Broadcast .div_broadcast .list_data_week .opt_file .color_1{
    width: 0px;
    height: 0px;
    border-top: 41px solid transparent;
    border-right: 20px solid #ffb500;
}
.Page_Broadcast .div_broadcast .list_data_week .opt_file .btn_link{
    color: #002765;
    background-color: #ffb500;
}
.Page_Broadcast .div_broadcast .list_data_week .opt_file .color_2{
    width: 0px;
    height: 0px;
    border-top: 41px solid transparent;
    border-right: 20px solid #002765;
    background-color: #ffb500;
}
.Page_Broadcast .div_broadcast .list_data_week .opt_file .btn_file{
    color: #ffb500;
    background-color: #002765;
}
.Page_Broadcast .div_broadcast .list_data_week .div_img{
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 180px;
    position: relative;
    width: 100%;
}
.Page_Broadcast .div_broadcast .list_data_week .div_img .div_logotipo{
    height: 40px;
    left: 5%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.Page_Broadcast .div_broadcast .list_data_week .div_img .div_logotipo .logotipo{
    height: 100%;
    width: 100%;
    display: flex;
}
.Page_Broadcast .div_broadcast .list_data_week .div_img .subtitle{
    bottom: 60%;
    color: #ededed;
    font-size: 16px;
    position: absolute;
    right: 5%;
    text-transform: uppercase;
}
.Page_Broadcast .div_broadcast .list_data_week .div_img .pdf_title{
    bottom: 26%;
    color: #ededed;
    font-size: 54px;
    font-weight: 900;
    line-height: 54px;
    position: absolute;
    right: 5%;
    text-transform: uppercase;
}


.Page_Broadcast .div_broadcast .list_data_week .div_week{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 40px 10px;
    font-family: 'Inter';

    background-color: #ededed;
}
.Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 80%;
}
.Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week .show_week{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week table{
    padding: 0;
}
.Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week table tr{
    background: transparent;
}
.Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week table td{
    border: none;
    padding: 4px 0px;
}
.Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week .week_type{
    width: 76px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week .week_type .color_week{
    background-color: #002765;
    color: #ededed;
    border-radius: 4px;
    width: calc(100% - 12px);
    padding: 6px;
}
.Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week .title{
    font-weight: 600;
    font-size: 10px;
    padding: 4px;
}
.Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week .week_show{
    text-align: center;
    cursor: pointer;
}
.Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week .week_show .color_date{
    background-color: #ffb500;
    color: #002765;
    border-radius: 4px;
    font-weight: 600;
    width: calc(100% - 12px);
    padding: 6px;
}


.Page_Broadcast .div_broadcast .list_data_week .div_contact{
    background-color: #3f3f3f;
    color: #ededed;
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.Page_Broadcast .div_broadcast .list_data_week .div_contact .title{
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}
.Page_Broadcast .div_broadcast .list_data_week .div_contact .div_data{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
}
.Page_Broadcast .div_broadcast .list_data_week .div_contact .div_data .show_data_contact{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
.Page_Broadcast .div_broadcast .list_data_week .div_contact .div_data .show_data_contact .div_icon{
    display: flex;
}
.Page_Broadcast .div_broadcast .list_data_week .div_contact .div_data .show_data_contact .div_icon .img_icon{
    width: 24px;
    height: auto;
}
.Page_Broadcast .div_broadcast .list_data_week .div_contact .div_data .show_data_contact .div_name{
    font-size: 12px;
}

@media only screen and (max-width: 950px) {
    .Page_Broadcast {
        padding-top: 70px;
    }
}
@media only screen and (max-width: 890px) {
    .Page_Broadcast .div_broadcast{
        width: calc(100% - 80px);
    }
}
@media only screen and (max-width: 680px) {
    .Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week{
        width: 90%;
    }
    .Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week .week_type{
        width: 44px;
    }
    .Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week .show_week{
        font-size: 12px;
    }
}
@media only screen and (max-width: 580px) {
    .Page_Broadcast .container {
        width: calc(100% - 32px);
    }
    .Page_Broadcast .div_broadcast .list_data_week .opt_file .btn{
        font-size: 12px;
    }
    .Page_Broadcast .div_broadcast .list_data_week .opt_file .color_1, 
    .Page_Broadcast .div_broadcast .list_data_week .opt_file .color_2{
        border-top: 38px solid transparent;
    }
    .Page_Broadcast .div_broadcast .list_data_week .div_img{
        height: 110px;
    }
    .Page_Broadcast .div_broadcast .list_data_week .div_img .div_logotipo{
        height: 20px;
    }
    .Page_Broadcast .div_broadcast .list_data_week .div_img .subtitle{
        font-size: 10px;
    }
    .Page_Broadcast .div_broadcast .list_data_week .div_img .pdf_title{
        font-size: 40px;
        line-height: 38px;
    }
    .Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week{
        width: 96%;
    }
    .Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week .title{
        font-size: 6px;
        line-height: 6px;
    }
    .Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week .week_type{
        width: 38px;
    }
    .Page_Broadcast .div_broadcast .list_data_week .div_week .div_list_week .show_week{
        font-size: 8px;
        line-height: 6px;
    }
    .Page_Broadcast .div_broadcast .list_data_week .div_contact{
        padding: 16px 0;
        gap: 10px;
    }
    .Page_Broadcast .div_broadcast .list_data_week .div_contact .div_data{
        gap: 6px;
    }
    .Page_Broadcast .div_broadcast .list_data_week .div_contact .div_data .show_data_contact .div_icon .img_icon{
        width: 16px;
    }
    .Page_Broadcast .div_broadcast .list_data_week .div_contact .div_data .show_data_contact .div_name{
        font-size: 6px;
        line-height: 6px;
    }
}