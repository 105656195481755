.Page_About{
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding-top: 60px;
}

/* about */
.Page_About .show_data_about{
    display: flex;
    flex-direction: column;
    width: calc(100% - 80px);
}
.Page_About .show_data_about .show_data{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 60px;
}
.Page_About .show_data_about .show_data .div_text{
    font-family: 'Inter';
    font-size: 16px;
    width: calc(100% - 350px);
}
.Page_About .show_data_about .show_data .div_text .text{
    text-align: left;
}
.Page_About .show_data_about .show_data .div_text .data_city{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.Page_About .show_data_about .show_data .div_text .data_city .icon{
    width: 36px;
    text-align: center;
}
.Page_About .show_data_about .show_data .div_text .data_city .icon .img{
    width: auto;
    height: 40px;
}
.Page_About .show_data_about .show_data .div_text .data_city .title{
    font-size: 24px;
    line-height: 24px;
    font-family: 'Bebas Neue';
    font-weight: 500;
    color: rgb(0 39 104);
    flex-grow: 1;
}
.Page_About .show_data_about .show_data .div_img{
    width: 350px;
    height: auto;
    text-align: center;
}
.Page_About .show_data_about .show_data .div_img .img{
    width: 100%;
    height: auto;
}
/* end */


/* data number */
.Page_About .show_data_number{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 39 104);
}
.Page_About .show_data_number .container{
    padding: 0;
    display: flex;
    flex-direction: row;
    width: calc(100% - 80px);
}
.Page_About .show_data_number .data_number{
    width: 25%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 0px;
    gap: 10px;
}
.Page_About .show_data_number .data_number .number{
    color: #ffb500;
    font-weight: 700;    
    font-family: 'Bebas Neue';
    font-size: 70px;
    line-height: 60px;
}
.Page_About .show_data_number .data_number .title{
    font-size: 24px;
    line-height: 24px;
    font-family: 'Bebas Neue';
    font-weight: 500;
    color: #ffffff;
    white-space: nowrap
}
/* end */


/* data city */
.Page_About .show_data_city{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.Page_About .show_data_city .title_page{
    text-align: center;
}
.Page_About .show_data_city .show_data{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 80px;
}
.Page_About .show_data_city .show_data .div_text{
    font-family: 'Inter';
    font-size: 16px;
    width: 50%;
}
.Page_About .show_data_city .show_data .div_text .text{
    text-align: left;
}
.Page_About .show_data_city .show_data .div_text .data_city{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.Page_About .show_data_city .show_data .div_text .data_city .icon{
    width: 36px;
    text-align: center;
}
.Page_About .show_data_city .show_data .div_text .data_city .icon .img{
    width: auto;
    height: 56px;
}
.Page_About .show_data_city .show_data .div_text .data_city .title{
    font-size: 54px;
    line-height: 54px;
    font-family: 'Bebas Neue';
    font-weight: 500;
    color: rgb(0 39 104);
    flex-grow: 1;
}
.Page_About .show_data_city .show_data .div_map{
    width: 50%;
    height: auto;
    text-align: right;
}
.Page_About .show_data_city .show_data .div_map .img{
    width: 100%;
    height: 100%;
}
/* end */


/* percent */
.Page_About .show_data_percent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 39 104);
    color: #ffffff;
    padding: 80px 0px;
}
.Page_About .show_data_percent .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
}
.Page_About .show_data_percent .data_percent{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
}
.Page_About .show_data_percent .data_percent .number{
    font-size: 80px;
    line-height: 70px;
    font-weight: 700;
    font-family: 'Bebas Neue';
    width: 30%;
    text-align: right;
    letter-spacing: 4px;
}
.Page_About .show_data_percent .data_percent .title{
    font-size: 32px;
    flex-grow: 1;
}
.Page_About .show_data_percent .data_percent .name_title{
    font-size: 32px;
    line-height: 32px;
}
.Page_About .show_data_percent .data_percent .title .subtitle{
    font-size: 14px;
    color: #ffb500;    
}
/* end */


@media only screen and (max-width: 850px) {
    .Page_About .show_data_about .show_data{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .Page_About .show_data_city .show_data{
        gap: 20px;
    }
    .Page_About .show_data_about .show_data .div_text{
        width: 100%;
    }
    .Page_About .show_data_city .show_data .div_text .data_city{
        gap: 10px;
    }
    .Page_About .show_data_city .show_data .div_text .data_city .icon .img{
        height: 36px;
    }
    .Page_About .show_data_city .show_data .div_text .data_city .title{
        font-size: 36px;
        line-height: 38px;
    }
    .Page_About .show_data_about .show_data .div_img{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    

    .Page_About .show_data_number .data_number{
        padding: 40px 0px;
    }
    .Page_About .show_data_number .data_number .title{
        font-size: 16px;
        line-height: 16px;
    }
    .Page_About .show_data_number .data_number .number{
        font-size: 32px;
        line-height: 32px;
    }

    .Page_About .show_data_percent .data_percent .number{
        font-size: 46px;
        line-height: 43px;
    }
    .Page_About .show_data_percent .data_percent .title .subtitle{
        font-size: 8px;
    }
    .Page_About .show_data_percent .data_percent .name_title {
        font-size: 16px;
        line-height: 20px;
    }
}
@media only screen and (max-width: 700px) {
    .Page_About .show_data_number .container{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .Page_About .show_data_number .data_number{
        padding: 20px 0px;
        width: 100%;
        flex-direction: row;
    }
    .Page_About .show_data_number .data_number .number{
        width: 30%;
        text-align: right;
    }
    .Page_About .show_data_number .data_number .title{
        flex-grow: 1;
    }
}
@media only screen and (max-width: 580px) {
    .Page_About .container {
        width: calc(100% - 32px);
    }
    .Page_About .show_data_city .show_data .div_text .data_city .title{
        font-size: 32px;
        line-height: 32px;
    }
    .Page_About .show_data_city .show_data .div_text .data_city .icon .img{
        height: 26px;
    }
    .Page_About .show_data_city .show_data .div_text{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    .Page_About .show_data_number .data_number{
        padding: 16px 0px;
    }
    .Page_About .show_data_number .data_number .number {
        font-size: 52px;
        line-height: 48px;
    }

    .Page_About .show_data_percent .data_percent .name_title {
        font-size: 12px;
        line-height: 14px;
    }
    .Page_About .show_data_percent .data_percent .title .subtitle {
        font-size: 6px;
        line-height: 6px;
    }
}