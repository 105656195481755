.div_menu{
    width: 100%;
    height: 100px;
    position: fixed;
    z-index: 2;
    background-color: #ffffff;
    transition: 0.5s;
}
.div_menu_scroll {
    height: 70px;
    box-shadow: 0px 0px 6px 0px #000000;
}
.div_menu .show_div_menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 100%;
}

.div_menu .show_div_menu .div_logotipo{
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 6;
}
.div_menu .show_div_menu .div_logotipo .logotipo{
    width: auto;
    height: 60px;
    cursor: pointer;
}

.div_menu .show_div_menu .opt_menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    font-weight: 700;
    font-family: 'inter';
}
.div_menu .show_div_menu .opt_menu .name_menu{
    cursor: pointer;
    position: relative;
    transition: 1s;
}
.div_menu .show_div_menu .opt_menu .menu_active {
    background-color: #002768;
    border-radius: 4px;
    bottom: -6px;
    height: 2px;
    position: absolute;
    width: 100%;
}
.div_menu .show_div_menu .opt_menu .name_menu:before {
    background-color: #002768;
    border-radius: 4px;
    bottom: -6px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    scale: 0 1;
    -webkit-transform-origin: left;
    transform-origin: left;
    transition: scale .36s;
    width: 100%;
}
.div_menu .show_div_menu .opt_menu .name_menu:hover::before{
    scale: 1;
}

.div_menu .show_div_menu .buttons__burger {
    --size: 2rem;
    --clr: #000;
    width: var(--size);
    height: calc(0.7 * var(--size));
    cursor: pointer;
    position: relative;
    display: none;
    z-index: 6;
    transition: 1s;
}
.div_menu .show_div_menu .buttons__burger #burger {
    display: none;
    width: 100%;
    height: 100%;
}
.div_menu .show_div_menu .buttons__burger span {
    display: block;
    position: absolute;
    width: 88%;
    border-radius: 0.5rem;
    border: 2px solid var(--clr);
    background-color: var(--clr);
    transition: 0.15s ease-in-out;
}
.div_menu .show_div_menu .buttons__burger span:nth-of-type(1) {
    top: 0;
    right: 0;
    transform-origin: right center;
}
.div_menu .show_div_menu .buttons__burger span:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
}
.div_menu .show_div_menu .buttons__burger span:nth-of-type(3) {
    top: 100%;
    right: 0;
    transform-origin: right center;
    transform: translateY(-100%);
}
.div_menu .show_div_menu .buttons__burger #burger:checked~span:nth-of-type(1) {
    transform: translateY(-30%) rotate(40deg);
    width: 50%;
    top: 50%;
}
.div_menu .show_div_menu .buttons__burger #burger:checked~span:nth-of-type(3) {
    transform: translateY(-70%) rotate(-40deg);
    width: 50%;
    top: 50%;
}

@media only screen and (max-width: 1040px) {
    .div_menu .show_div_menu .opt_menu .name_menu{
        font-size: 12px;
    }
}

@media only screen and (max-width: 950px) {
    .div_menu{
        height: 70px;
    }
    .div_menu .show_div_menu .div_logotipo .logotipo{
        height: 40px;
    }
    .div_menu .show_div_menu .buttons__burger {
        display: block;
    }
    .div_menu .show_div_menu .opt_menu{
        flex-direction: column;
        justify-content: center;
        gap: 30px;

        position: fixed;
        height: 100%;
        right: -134px;
        top: 0;
        z-index: 4;        
    }    
    .div_menu .show_div_menu .open_menu {
        width: 0;
        right: -134px;
        
        animation: open_menu 1s;
        animation-fill-mode: forwards;

        background-color: rgb(57 57 57 / 70%);
        color: #ffffff;
    }
    @keyframes open_menu {
        0%{
            right: -134px;
        }
        100%{
            width: 100%;
            right: 0;
        }
    } 
    .div_menu .show_div_menu .close_menu {
        width: 0;
        right: 0px;
        
        animation: close_menu 1s;
        animation-fill-mode: forwards;
    }
    @keyframes close_menu {
        0%{
            width: 100%;
            right: 0;
        }
        100%{
            right: -134px;
            width: 0;
        }
    }
    .div_menu .show_div_menu .opt_menu .name_menu{
        font-size: 16px;
    }
}