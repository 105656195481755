.Page_Client {
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding: 60px 0px;
}
.Page_Client .container{
    width: calc(100% - 80px);
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.Page_Client .list_clients{
    border-top: 1px solid rgb(0 39 104);
    border-bottom: 1px solid rgb(0 39 104);
}
.Page_Client .list_clients .div_arrow{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    padding-top: 40px;
}
.Page_Client .list_clients .div_arrow .arrow{
    width: 40px;
    height: 40px;
    border: 4px solid #ffb500;
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Page_Client .list_clients .div_arrow .arrow .icon{
    width: 32px;
    height: 32px;
}
.Page_Client .list_clients .div_arrow .arrow .icon_left {
    transform: rotate(180deg);
}

.Page_Client .list_clients .carousel {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 40px 0px;
    gap: 50px;
}
.Page_Client .list_clients .carousel::-webkit-scrollbar {
    display: none;
}
.Page_Client .list_clients .carousel .item {
    background-color: white;
    margin: 10px;
    padding: 10px;
    width: 280px;
    border-radius: 16px;
    flex: none;
}
.Page_Client .list_clients .carousel .item .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Page_Client .list_clients .carousel .item .info {
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Page_Client .list_clients .carousel .item .info span {
    display: block;
    text-align: center;
    color: #1e1e1e;
    padding: 5px;
    border-radius: 10px;
}
.Page_Client .list_clients .carousel .item .info .name {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 10px 0;
    color: #00a8fb;
}
.Page_Client .list_clients .carousel .item .info .oldPrice {
    font-size: 0.8rem;
    text-decoration: line-through;
    flex-grow: 1;
    color: #e81a5d;
}
.Page_Client .list_clients .carousel .item .info .price {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #ff7e3b;
}

.Page_Client .list_clients .carousel .logotipos{
    width: 226px;
    min-width: 226px;
    height: 130px;
    display: flex;
    transition: 0.5s;
}
.Page_Client .list_clients .carousel .logotipos:hover{
    transform: scale(0.96);
}
.Page_Client .list_clients .carousel .logotipos .logo{
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 580px) {
    .Page_Client .container {
        width: calc(100% - 32px);
    }
    .Page_Client .list_clients .div_arrow{
        justify-content: center;
    }
    .Page_Client .list_clients .carousel{
        gap: 20px;
    }
    .Page_Client .list_clients .carousel .logotipos{
        width: 140px;
        min-width: 140px;
        height: 80px;
    }
}
@media only screen and (max-width: 460px) {
    .Page_Client .list_clients .carousel .logotipos{
        width: 100px;
        min-width: 100px;
        height: 60px;
    }
}