.Page_Contact {
    display: flex;
    flex-direction: column;
    gap: 80px;
    background-color: rgb(0 39 104);
    color: #ffffff;
    padding: 60px 0px;
}
.Page_Contact .container{
    width: calc(100% - 80px);
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.Page_Contact .title_page{
    text-align: center;
}

.Page_Contact .show_data_contact{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    gap: 60px;
}
.Page_Contact .show_data_contact .div_form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 30%;
}
.Page_Contact .show_data_contact .div_form .form{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.Page_Contact .show_data_contact .div_form .form .wave-group {
    position: relative;
    margin-bottom: 26px;
}
.Page_Contact .show_data_contact .div_form .form .wave-group .input {
    font-size: 16px;
    padding: 10px;
    display: block;
    width: calc(100% - 22px);
    border: 1px solid #ffb500;
    background: transparent;
    color: #ffffff;
}
.Page_Contact .show_data_contact .div_form .form .wave-group .textarea{
    resize: none;
    min-height: 80px;
}
.Page_Contact .show_data_contact .div_form .form .wave-group .input:focus {
    outline: none;
}
.Page_Contact .show_data_contact .div_form .form .wave-group .label {
    color: #ffffff;
    font-size: 14px;
    line-height: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 12px;
    display: flex
}
.Page_Contact .show_data_contact .div_form .form .wave-group .label-char {
    transition: 0.2s ease all;
    transition-delay: calc(var(--index) * .05s);
}
.Page_Contact .show_data_contact .div_form .form .wave-group .input:focus~label .label-char, 
.Page_Contact .show_data_contact .div_form .form .wave-group .input:valid~label .label-char {
    transform: translateY(-20px);
    font-size: 14px;
    color: #ffb500;
    background-color: rgb(0 39 104);
}
.Page_Contact .show_data_contact .div_form .form .wave-group .label-char:nth-child(1){
    padding-left: 10px;
}
.Page_Contact .show_data_contact .div_form .form .wave-group .label-char:nth-last-child(-n + 1){
    padding-right: 10px;
}
.Page_Contact .show_data_contact .div_form .form .wave-group .bar {
    position: relative;
    display: block;
    width: 200px;
}
.Page_Contact .show_data_contact .div_form .form .wave-group .bar:before {
    left: 50%;
}
.Page_Contact .show_data_contact .div_form .form .wave-group .bar:after {
    right: 50%;
}
.Page_Contact .show_data_contact .div_form .form .wave-group .input:focus~.bar:before, 
.Page_Contact .show_data_contact .div_form .form .wave-group .input:focus~.bar:after {
    width: 50%;
}
.Page_Contact .show_data_contact .div_form .form .div_btn{
    text-align: right;
}
.Page_Contact .show_data_contact .div_form .form .div_btn .btn{
    background-color: #ffb500;
    color: rgb(0 39 104);
    border: transparent;
    padding: 10px 30px;
    font-family: 'Inter';
    font-size: 16px;
    cursor: pointer;
}
.Page_Contact .show_data_contact .data_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
    width: auto;
}
.Page_Contact .show_data_contact .data_text .email{
    color: #ffb500;
    font-size: 14px;
    font-weight: 600;
}
.Page_Contact .show_data_contact .data_text .text{
    font-size: 14px;
    text-align: left;
}
.Page_Contact .show_data_contact .data_text .div_icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 30px;
}
.Page_Contact .show_data_contact .data_text .div_icons .img{
    width: 40px;
    height: 40px;
}

@media only screen and (max-width: 1030px) {
    .Page_Contact .show_data_contact{
        gap: 30px;
    }
    .Page_Contact .show_data_contact .div_form{
        width: 40%;
    }
}
@media only screen and (max-width: 680px) {
    .Page_Contact .show_data_contact{
        flex-direction: column;
    }
    .Page_Contact .show_data_contact{
        gap: 60px;
    }
    .Page_Contact .show_data_contact .div_form{
        width: 100%;
    }
    .Page_Contact .show_data_contact .data_text{
        width: 100%;
        align-items: center;
    }
    .Page_Contact .show_data_contact .data_text .email,
    .Page_Contact .show_data_contact .data_text .text,
    .Page_Contact .show_data_contact .data_text .div_icons{
        width: 100%;
        text-align: center;
    }
}
@media only screen and (max-width: 580px) {
    .Page_Contact .container {
        width: calc(100% - 32px);
    }
}