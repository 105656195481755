.PopUp .Confirmation{
    width: 400px;
    margin: 10% auto;
}
.PopUp .Confirmation .title{
    justify-content: center;
}
.PopUp .Confirmation .content{
    flex-direction: row;
}
.PopUp .Confirmation .div_data{
    flex-direction: column;
}
.PopUp .Confirmation .count_time{
    color: rgb(0 39 104);
    font-size: 52px;
    padding: 0;
    margin: 0;
    font-family: 'inter';
}


@media only screen and (max-width: 580px) {
    .PopUp .Confirmation{
        width: 90%;
    }
}